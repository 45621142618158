import React from "react";
import { Tooltip } from "react-tooltip";

const TooltipContainer = ({ id, content, placement, children, style }) => {
  return (
    <div
      data-tooltip-id={id}
      data-tooltip-content={content}
      className="max-w-max"
      style={style ? style : {}}
    >
      {children}
      <Tooltip id={id} place={placement} className="z-10"/>
    </div>
  );
};

export default TooltipContainer;
