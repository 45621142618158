import React, { useState } from "react";
import { Icon } from "react-icons-kit";
import { ic_thumb_up } from "react-icons-kit/md/ic_thumb_up";
import RoundedContainer from "./Common/RountedContainer";
import Rating from "./Rating";
import { useAppData } from "../context/AppContext";
import apiRequest from "../api/api";
import toast from "react-hot-toast";

const FeedBack = ({ isFormOpen, setIsFormOpen, conversation }) => {
  const { authUser, userSelectedDocs } = useAppData();

  const [feedback, setFeedback] = useState("");
  const [rating, setRating] = useState(0);


  // Handle feedback change
  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const onCloseFeedback = () => {
    setIsFormOpen(false);
  };

  // Submit function to send data
  const handleSubmitFeedBack = async () => {
    if (feedback == "") {
      toast.error("Please provide feedback!");
      return;
    }
    const dataToSend = {
      name: authUser.username,
      email: authUser.useremail,
      conversation: conversation,
      rating: rating,
      feedback: feedback,
    };
    console.log(dataToSend);

    toast.success("Feeback submitted!");
    setIsFormOpen(false);

    try {
      const responseData = await apiRequest(
        "POST",
        "/feedback",
        JSON.stringify(dataToSend)
      );
    } catch (err) {
      const feedbackErr = err.response.data?.message;
      toast.error(feedbackErr);

      console.log(err, "feedbackerr");
    }

    //  clear the form after submission
    setRating(0);
    setFeedback("");
  };

  return (
    <>
      {isFormOpen && (
        <div
          className={`feedback-form ${
            isFormOpen ? "open" : "feedback-form-close"
          } relative rounded-xl`}
        >
          <div className="feedback-form-container">
            <div className="flex justify-between items-center">
              <h3 className="font-bold">Provide Feedback</h3>
              <Rating rating={rating} setRating={setRating} />
            </div>
            <form>
              <div style={{ width: "100%" }}>
                <textarea
                  value={feedback}
                  onChange={handleFeedbackChange}
                  className="feedback-textarea rounded-md p-2 w-full h-28"
                  id="feedback"
                  placeholder="Type your feedback here..."
                ></textarea>
              </div>
            </form>
            <div className="flex justify-between">
              <button
                onClick={onCloseFeedback}
                className="mt-2 py-2 px-4 text-[#878787] font-bold rounded-md cursor-pointer border border-[#c9c9c9]"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmitFeedBack}
                className="mt-2 py-2 px-4 bg-[#878787] text-white font-bold rounded-md cursor-pointer"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default FeedBack;
