import React from 'react'
import { Link } from 'react-router-dom'; 


function AdminSidebar() {
  return (
  
        <aside className="w-64 bg-gray-800 text-black bg-silver">
          <div className="p-4">
            <h2 className="text-2xl font-semibold">Admin Panel</h2>
          </div>
          <nav className="flex flex-col p-4 space-y-4">
            <Link to="/admin" className="text-black hover:bg-gray-700 px-3 py-2 rounded-md">
              Dashboard
            </Link>
            <Link to="/admin/llms" className="text-black hover:bg-gray-700 px-3 py-2 rounded-md">
            Settings
            </Link>
          </nav>
        </aside>
      );

    }
export default AdminSidebar