import React, { useState, useEffect } from "react";
import apiRequest from "../api/api";

import { useAppData } from "../context/AppContext";
import toast from "react-hot-toast";
import { fas } from "@fortawesome/free-solid-svg-icons";

function ContactUs() {
  const { authUser } = useAppData();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [err, setErr] = useState({ name: false, email: false, message: false });

  useEffect(() => {
    // Initialize the email state with the authUser's email
    setEmail(authUser.useremail);
    setName(authUser.username);
  }, [authUser]);

  const handleClickReset = () => {
    setMessage("");
  };

  const submitContactForm = async () => {
    if (name == "" || email == "" || message == "") {
      toast.error("All Feilds are required");
      setErr({
        name: name === "",
        email: email === "",
        message: message === "",
      });
      return;
    }

    const dataToSend = {
      name: name,
      email: email,
      message: message,
    };

    try {
      const responseData = await apiRequest(
        "POST",
        "/contact",
        JSON.stringify(dataToSend)
      );

      toast.success("Your message has been sent! Thank you for your Feedback");
      setMessage("");
      setErr(false)
    } catch (err) {
      const contactErr = err.response.data?.message;
      toast.error(contactErr);
    }
  };

  return (
    <div>
      <div className="contact-screen-body mx-auto max-w-7xl">
        <h2 className="text-center text-[#f6874c] font-bold text-lg mt-2 ">
          Contact Us
        </h2>

        <div className="contact-screen-body-item ">
          <div className="contact-app-form">
          <div className="contact-app-form-group contact-message flex items-center gap-2 justify-between">
          <h6 className="text-white w-20">Name</h6>
              <input
                style={{ border: err.name ? "1px solid red" : "" }}
                className="contact-app-form-control"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="contact-app-form-group contact-message flex items-center gap-2">
            <h6 className="text-white  w-20">Email</h6>

              <input
                style={{ border: err.email ? "1px solid red" : "" }}
                className="contact-app-form-control"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="contact-app-form-group contact-message flex items-center gap-2">
            <h6 className="text-white  w-20 ">Message</h6>

              <textarea
                style={{ border: err.message ? "1px solid red" : "" }}
                value={message}
                placeholder="Provide your feedback here..."
                className="contact-app-form-control"
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <div className="contact-app-form-group contact-buttons flex justify-between">
              <button
                onClick={handleClickReset}
                className="contact-app-form-button"
              >
                Clear
              </button>
              <button
                onClick={submitContactForm}
                style={{
                  background: "#f6874c",
                  color: "black",
                  fontWeight: "600",
                }}
                className="contact-app-form-button "
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
