import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";

const FileUpload = ({
  onFilesUploaded,
  displayStyles,
  handleFileData,
  uploadedFiles,
  uploadInProgress,
  SupportedFiles
}) => {
  const handleFileSelect = useCallback(
    (acceptedFiles) => {
      const existingFileNames = new Set(
        uploadedFiles.map((file) => file.file.name)
      );
      const duplicates = acceptedFiles.filter((file) =>
        existingFileNames.has(file.name)
      );
      const newFiles = acceptedFiles.filter(
        (file) => !existingFileNames.has(file.name)
      );

      if (duplicates.length > 0) {
        toast.error(<DuplicateFilesToast files={duplicates} />);
      }

      onFilesUploaded(newFiles); // Notify parent about new files
    },
    [uploadedFiles, onFilesUploaded]
  );
  const { getRootProps, getInputProps, fileRejections, open } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
      "text/plain": [".txt"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "application/vnd.ms-powerpoint": [".ppt"],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        [".pptx"],
      "application/epub+zip": [".epub"],
      "application/rtf": [".rtf"],
    },
    onDrop: handleFileSelect,
  });

  useEffect(() => {
    handleFileData(fileRejections);
  }, [fileRejections, handleFileData]);

  const DuplicateFilesToast = ({ files }) => (
    <div style={{ minWidth: "700px", width: "100%" }}>
      <p>Files already selected:</p>

      <ul>
        {files.map((file) => (
          <li style={{ listStyle: "inside" }} key={file.name}>
            {file.name}
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <>
      <div
        style={{ opacity: uploadInProgress ? 0.5 : 1 }}
        {...getRootProps()}
        className={`${
          displayStyles === "questionasnComp" ? "" : "file-upload-container"
        }`}
      >
        <input disabled={uploadInProgress} {...getInputProps()} />
        {displayStyles === "questionasnComp" ? (
          <button
            type="button"
            onClick={open}
            style={{ border: "none", background: "none" }}
          >
            <FontAwesomeIcon
              icon={faPaperclip}
              size="2x"
              style={{
                width: "18px",
                verticalAlign: "middle",
                marginTop: "12px",
              }}
            />
          </button>
        ) : (
          <p>Drag and drop some files here, or click to select files</p>
        )}
      </div>
      <div className="flex justify-center gap-2 my-2 flex-wrap sm:my-5 sm:gap-5">
          {SupportedFiles.map((file, key) => (
            <span
              key={key}
              className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"
            >
              {file}
            </span>
          ))}
        </div>
    </>
  );
};

export default FileUpload;
