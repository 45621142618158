import React, { useState } from 'react'
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

function useResetPass() {
    const { token } = useParams()

    const navigat = useNavigate()

    const [loading, setLoading] = useState(false)

    const resetPassword = async (password) => {
        const success = handleInputErrors({ password })

        if (!success) return;
        setLoading(true)
        try {
            const res = await fetch("POST,"`/reset_password/` + token,JSON.stringify({ password }))

            if (res.error) {
                throw new Error(res.error)
            }
            if (res) {
                toast.success("password updated successfully")

                navigat("/login")

            }


        }

        catch (err) {
            toast.error(err.message)
        }

        finally {
            setLoading(false)

        }

    }

    return { loading, resetPassword };
}

export default useResetPass


function handleInputErrors({ password }) {
    if (!password) {
        toast.error("please enter password")
        return false
    }

    return true
}