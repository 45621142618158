import React, { useState, useEffect } from "react";
import SideBar from "./SideBar";
import QuestionAnswers from "./QuestionAnswers";
import { apiEndPoint, publicIP } from "./configuratiion";
import { useAppData } from "../context/AppContext";
import apiRequest from "../api/api";

function Conversation() {
  const [activeDocument, setActiveDocument] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [err, setError] = useState("");
  const [loadingText, setLoadingText] = useState(false);
  const [loading, setLoading] = useState(false);
  const { authUser, userSelectedDocs } = useAppData();
  const [firstConversation, setFirstConversation] = useState(true);



  useEffect(() => {
    const fetchConversations = async () => {
      try {
        // Fetch user's files
        const responseData = await apiRequest( "GET", `/conversations/${authUser?.user_id}`, {
        });

        if (responseData.message === "No conversations found for this user") {
                if (firstConversation) {
                  console.error("No conversations found for this user");
                  setFirstConversation(false);
                }
              } else {
                setConversations(responseData);
                setFirstConversation(false);
              }
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };

    fetchConversations();
  }, []);

  const deleteConversations = async () => {
    try {
      const response = await apiRequest("DELETE" ,`/conversations/${authUser.user_id}`)
      if (response.message=="Conversation deleted successfully") {
        setConversations([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // useEffect(() => {
  //   fetchConversations();
  // }, []);

  return (
    <div className="">

      <div className="main-sidebar-chat-box flex-1">
        <SideBar
          activeDocument={activeDocument}
          setActiveDocument={setActiveDocument}
          err={err}
          setError={setError}
          loadingText={loadingText}
          setLoadingText={setLoadingText}
          loading={loading}
          setLoading={setLoading}
          conversations={conversations}
        />

        <QuestionAnswers
          activeDocument={activeDocument}
          setActiveDocument={setActiveDocument}
          err={err}
          setError={setError}
          loadingText={loadingText}
          setLoadingText={setLoadingText}
          loading={loading}
          setLoading={setLoading}
          conversations={conversations}
          setConversations={setConversations}
          deleteConversations={deleteConversations}
        />
      </div>
    </div>
  );
}

export default Conversation;
