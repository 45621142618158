import React, { useState } from 'react'
import FilesUploadedData from '../components/FilesUploadedData'
import Navbar from '../components/Navbar'

function Home() {


    return (
        <div className='flex flex-col h-safe-screen'>
            <Navbar />
            <div
                className="home-page-wrapper flex-1"
                style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    display: 'flex',
                    overflow: "hidden",
                    background:"#414141",
                }}
                >

                <FilesUploadedData/>
            </div>
        </div>
    )
}

export default Home