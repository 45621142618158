import React from "react";

export default function RoundedButton({
  label,
  icon,
  tooltip,
  onClick,
  disabled,
  ref
}) {
  return (
    <div
      ref={ref ? ref:null}
      className="flex gap-2 p-2 w-fit bg-[#d6d6d6] rounded-lg h-9 cursor-pointer items-center"
      title={tooltip}
      onClick={() => !disabled && onClick && onClick()}
      style={{ opacity: disabled ? 0.5 : 1 }}
    >
      {icon ?? null}
      {label ? <span>{label}</span> : null}
    </div>
  );
}
