import React, { useState } from 'react'
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useAppData } from '../context/AppContext';
import { apiEndPoint, publicIP } from '../components/configuratiion';
import apiRequest from '../api/api';

function useForgotPass() {
    const { setAuthUser } = useAppData()

    const navigat = useNavigate()

    const [loading, setLoading] = useState(false)

    const forgotPassword = async (email) => {
        const success = handleInputErrors({ email })

        if (!success) return;
        setLoading(true)
        try {
            const res = await apiRequest("POST",`/forgotpassword`, JSON.stringify({ email,endPoint:apiEndPoint }))

            if (res.error) {
                throw new Error(res.error)
            }
            if (res) {
                toast.success("Check your email for reset password link")


            }
          

        }

        catch (err) {
            toast.error(err.message)
        }

        finally {
            setLoading(false)

        }

    }

    return { loading, forgotPassword };
}

export default useForgotPass


function handleInputErrors({ email }) {
    if (!email ) {
        toast.error("please enter email")
        return false
    }

    return true
}