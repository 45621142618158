import React, { useState, useEffect } from "react";
import apiRequest from "../../api/api";
import { edit } from "react-icons-kit/fa/edit";
import Icon from "react-icons-kit";
import { toast } from "react-hot-toast";

function LLMSTable() {
  const [llms, setLlms] = useState([]);
  const [editedLlms, setEditedLlms] = useState([]); // Track edited LLMS
  const [editMode, setEditMode] = useState({}); // Track which row is in edit mode
  const [defaultLlm, setDefaultLlm] = useState(null);



  const fetchLlms = async () => {
    try {
      const responseData = await apiRequest("GET", "/llms");
      console.log(responseData);
      setLlms(responseData);
      setEditedLlms(responseData);
      const defaultLlm = responseData.find((llm) => llm.is_default);
      setDefaultLlm(defaultLlm ? defaultLlm.id : null);
    } catch (error) {
      console.error("Error fetching LLMS data:", error);
    }
  };

  useEffect(() => {
    fetchLlms();
  }, []);

  // Handle changes to enabled field
  const handleEnabledChange = (id, enabled) => {
    setEditedLlms(
      editedLlms.map((llm) => (llm.id === id ? { ...llm, enabled } : llm))
    );
  };

  // Handle changes to API key
  const handleApiKeyChange = (id, value) => {
    // window.alert(value)
    // if (value=="" || !value){
    //   toast.error("Feild required")
    //   return
    // }
    setEditedLlms((prevLlms) =>
      prevLlms.map((llm) => (llm.id === id ? { ...llm, api_key: value } : llm))
    );
  };
  // Handle Apply button click
  const handleApply = async () => {
    const missingApikeys = editedLlms.some((llm) => llm.api_key == "");
    const modelName = editedLlms
      .filter((llm) => llm.api_key == "")
      .map((model) => model.model_name);

    if (missingApikeys) {
      toast.error(
        `Api key required for ${modelName.map((name) => "" + name + " ")}`
      );
    }

    const data = {
      llms: editedLlms,
      defaultLlm: defaultLlm, // ID of the selected default LLM
    };

    try {
      // Send updated LLMS data to the server
      const apiData = await apiRequest("PUT", "/llms/update", data);
      console.log(apiData, "apidata");

      await fetchLlms();
      setEditMode(false);
    } catch (error) {
      console.log(error.response.data.message, "errortable");
      toast.error(error.response.data.message);
      return;

      // console.error('Error updating LLMS data:', error);
    }
  };

  const handleEditClick = (id) => {
    setEditMode((prev) => ({
      ...prev,
      [id]: !prev[id], // Toggle edit mode for the clicked row
    }));
  };

  const handleDefaultChange = (event) => {
    setDefaultLlm(event.target.value);
  };
  const enabledLlms = editedLlms.filter((llm) => llm.enabled);

  return (
    <div>
      <h3 className=" text-xl font-semibold mb-2">LLLM Models</h3>
      <div className="w-3/5 ">
        <table className="min-w-full bg-[#ebebeb] ">
          <thead>
            <tr>
              <th className="text-left py-3 px-4 border-b border-gray-300">
                Vendor
              </th>
              <th className="text-left py-3 px-4 border-b border-gray-300">
                Model
              </th>
              <th className="text-left py-3 px-4 border-b border-gray-300">
                Enabled
              </th>
              <th className="text-left py-3 px-4 border-b border-gray-300">
                API Key
              </th>
            </tr>
          </thead>
          <tbody>
            {llms?.map((llm) => (
              <tr key={llm.id}>
                <td className="py-2 px-4 ">{llm.vendor}</td>
                <td className="py-2 px-4 ">{llm.model_name}</td>
                <td className="py-2 px-4 ">
                  <input
                    type="checkbox"
                    checked={
                      editedLlms.find((e) => e.id === llm.id)?.enabled || false
                    }
                    onChange={(e) =>
                      handleEnabledChange(llm.id, e.target.checked)
                    }
                    className="form-checkbox"
                  />
                </td>
                <td className="py-2 px-4  flex justify-between gap-3">
                  {editMode[llm.id] ? (
                    <input
                      type="text"
                      value={
                        editedLlms.find((e) => e.id === llm.id)?.api_key || ""
                      }
                      onChange={(e) =>
                        handleApiKeyChange(llm.id, e.target.value)
                      }
                      className="form-input flex-1"
                    />
                  ) : (
                    <p>{llm.api_key}</p>
                  )}
                  <button
                    onClick={() => handleEditClick(llm.id)}
                    className="text-blue-500"
                  >
                    <Icon icon={edit} />
                  </button>
                </td>

                {/* <td className="py-2 px-4 border-b border-gray-300">
              
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex justify-between mt-5 items-center">
          <div className="flex items-center gap-4">
            <label htmlFor="defaultLlm" className="  font-bold ">
              Default LLM
            </label>
            <select
              id="defaultLlm"
              value={defaultLlm || ""}
              onChange={handleDefaultChange}
              className="form-select "
            >
              <option value="">Select Default LLM</option>
              {enabledLlms?.map((llm) => (
                <option key={llm.id} value={llm.id}>
                  {llm.model_name}
                </option>
              ))}
            </select>
          </div>

          <div className="">
            <button
              onClick={handleApply}
              className=" bg-[#343434] text-white px-6 py-2"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LLMSTable;
