import axios from "axios";
import { getServerEndpoint } from "../getServerEndPoint";

const serverEndpoint = getServerEndpoint();
const axiosInstance = axios.create({
  baseURL: serverEndpoint, //base URL
});
// Request interceptor to attach token to headers
axiosInstance.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem("auth-user") || "{}");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token.access_token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error, "error");
    // Handle specific errors here, like token expiration
    if (
      error.response &&
      (error.response.status === 401 )
    ) {
      localStorage.removeItem("auth-user");
      window.location.href = "/login";

    }

    if (error.response && error.response.status === 403) {
      //admin check

      window.location.href = "/";
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
