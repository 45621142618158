import { saveAs } from "file-saver";

// downloads the file
export class ConversationUtils {
  static exportConversation(conversations) {
    let exportContent = "";

    conversations.forEach((conversation) => {
      const { question, answer, model, documents } = conversation;

      exportContent += `Question:\n----------\n${question}\n\n`;
      exportContent += `Answer (Model: ${model}, sources: [${documents.join(
        ", "
      )}]):\n----------\n${answer}\n\n`;
      exportContent +=
        "-------------------------------------------------------------------\n\n";
    });

    // Get current date
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0]; // YYYY-MM-DD format

    // Create a Blob from the content and export as .txt file with the current date as the filename
    const blob = new Blob([exportContent], {
      type: "text/plain;charset=utf-8",
    });
    saveAs(blob, `conversation_export_${formattedDate}.txt`);
  }
}
