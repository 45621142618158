import React, { useState } from 'react'
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useAppData } from '../context/AppContext';
import { apiEndPoint, publicIP } from '../components/configuratiion';
import apiRequest from '../api/api';

function useLogin() {
    const { setAuthUser } = useAppData()

    const navigat = useNavigate()

    const [loading, setLoading] = useState(false)
    const login = async (email, password) => {
        const success = handleInputErrors({ email, password })

        if (!success) return;
        setLoading(true)
        try {
            const res = await apiRequest("POST",`/login`, JSON.stringify({ email, password }))
            console.log(res,"res")
            if (res.message==="Invalid credentials") {

                throw new Error("Invalid credentials")
            }

            if (res.message==="User is not registered") {

                throw new Error("User is not registered")
            }
            if (res.access_token) {
                console.log(res)
                localStorage.setItem("auth-user", JSON.stringify(res))
                setAuthUser(res)
                navigat("/")
            }

            if (res.is_admin){
                localStorage.setItem("auth-user", JSON.stringify(res))
                setAuthUser(res)
                navigat("/admin")

            }
        
        }

        catch (err) {
            console.log(err.response.data?.message,"err")
            const loginErr=err.response.data?.message
            toast.error(loginErr)
        }

        finally {
            setLoading(false)

        }

    }

    return { loading, login };
}

export default useLogin


function handleInputErrors({ email, password }) {
    if (!email || !password) {
        toast.error("please fill in all fields")
        return false
    }

    return true
}