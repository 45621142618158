import React, { useEffect, useState, useRef } from "react";
import logo1 from "../images/logo3.png";
import pdfIcon from "../images/pdfIcon.png";
import txtIcon from "../images/txtIcon.png";
import pptIcon from "../images/pptIcon.png";
import epubIcon from "../images/epubIcon.png";
import rftIcon from "../images/rftIcon.png";
import msWordIcon from "../images/msWordIcon.png";
import weburlIcon from "../images/weburlIcon.png";
import { useAppData } from "../context/AppContext";
import FileUploadPopup from "./FileUploadPopup";
import { sidebar } from "react-icons-kit/feather/sidebar";
import Icon from "react-icons-kit";
import { attachment } from "react-icons-kit/icomoon/attachment";
import TooltipContainer from "./Common/TooltipContainer";
import { ic_delete_outline } from "react-icons-kit/md/ic_delete_outline";
import RoundedButton from "./Common/RoundedButton";
import apiRequest from "../api/api";
import FilesUploadedData from "./FilesUploadedData";
import toast from "react-hot-toast";

const SIDEBAR_AUTO_CLOSE_MAX_SCREEN_WIDTH = 1000;

function SideBar({
  activeDocument,
  setActiveDocument,
  setError,
  setLoadingText,
  setLoading,
  loading,
  conversations,
}) {
  const { authUser, setUserSelectedDocs, userSelectedDocs } = useAppData();
  const [selectedConversations, setSelectedConversations] = useState([]);
  const [files, setFiles] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State variable for showing delete modal
  const [conversationToDelete, setConversationToDelete] = useState(null); // Store conversation to delete

  const [searchTerm, setSearchTerm] = useState("");
  const [showDeleteAllModal, setShowDeleteAllModal] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const sidebarRef = useRef();
  const toggleButtonRef = useRef();
  const [documentId, setDocumentId] = useState(null);

  const [isCollapsed, setIsCollapsed] = useState(
    localStorage.getItem("isSidebarCollapsed") == true ||
      localStorage.getItem("isSidebarCollapsed") == "true"
  );

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    localStorage.setItem("isSidebarCollapsed", isCollapsed);
  }, [isCollapsed]);

  const togglePopup = () => {
    setIsPopupOpen((prev) => !prev);
  };

  const handleConversationSelection = (conversationName) => {
    setActiveDocument(conversationName);
    // Toggle the selection status of the conversation
    setUserSelectedDocs((prevSelection) => {
      const updatedSelection = prevSelection.includes(conversationName)
        ? prevSelection.filter((name) => name !== conversationName)
        : [...prevSelection, conversationName];

      // Update localStorage with the new selection
      localStorage.setItem(
        "selectedConversations",
        JSON.stringify(updatedSelection)
      );

      return updatedSelection;
    });
    setError("");
    setLoadingText(false);
    setLoading(false);
  };

  const handleConversationSelectionDelete = (conversationName) => {
    // Store conversation to delete
    setConversationToDelete(conversationName);
    // Toggle delete modal visibility
    setShowDeleteModal(true);
  };

  const handleAllConversationsSelectionDelete = () => {
    setShowDeleteAllModal(true);
  };

  const handleDeleteAllConfirmation = async () => {
    const requestData = {
      files_path: selectedConversations,
      user_id: authUser.user_id,
    };

    try {
      const responseData = await apiRequest(
        "POST",
        `/delete_all_files`,
        JSON.stringify(requestData)
      );

      if (responseData.message == "File deletion process completed.") {
        setShowDeleteAllModal(false);
        setFiles([]);
        localStorage.setItem("selectedConversations", JSON.stringify([]));
        setUserSelectedDocs([]);
      }
    } catch (err) {
      if (err.response) {
        // Backend responded with an error (like 400 or 500 status)
        toast.error(err.response.data.message || "An error occurred.");
      } else if (err.request) {
        // No response was received
        toast.error("No response from server. Please try again later.");
      } else {
        // Something else caused an error
        toast.error("An error occurred while deleting files.");
      }
    }
  };

  const handleDeleteConfirmation = async () => {
    const requestData = {
      file_path: conversationToDelete,
      user_id: authUser.user_id,
    };
    try {
      const responseData = await apiRequest(
        "POST",
        `/delete_file`,
        JSON.stringify(requestData)
      );

      // Update local state immediately after successful API response
      if (responseData.message == "document deleted") {
        setFiles((prevFiles) => {
          const updatedFiles = prevFiles.filter(
            (conversation) => conversation.name !== conversationToDelete
          );

          // Update localStorage with the updated list of selected conversations
          const updatedSelection =
            JSON.parse(localStorage.getItem("selectedConversations")) || [];
          const newSelection = updatedSelection.filter(
            (name) => name !== conversationToDelete
          );
          localStorage.setItem(
            "selectedConversations",
            JSON.stringify(newSelection)
          );
          // window.location.reload()

          setUserSelectedDocs(newSelection);

          return updatedFiles;
        });
      }

      setShowDeleteModal(false);
    } catch (err) {
      const fileDeleting = err.response.data?.message;
      toast.error(fileDeleting);
      console.error("Error deleting file:", err.message);
    }
  };

  //getting users files
  useEffect(() => {
    const fetchFiles = async () => {
      try {
        // Fetch user's files
        const responseData = await apiRequest(
          "GET",
          `/files/${authUser?.user_id}`,
          {}
        );
        // const data = await response.json();
        setFiles(responseData);
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };

    fetchFiles();
  }, []);

  useEffect(() => {
    files?.length > 0 &&
      files.map((conversation) => {
        setSelectedConversations((prevValue) => {
          const updatedConversations = new Set([
            ...prevValue,
            conversation.name,
          ]);
          return Array.from(updatedConversations);
        });
      });
  }, [files]);

  // To initialize the state from localStorage when the component mounts
  useEffect(() => {
    const savedSelection = localStorage.getItem("selectedConversations");
    if (savedSelection) {
      setUserSelectedDocs(JSON.parse(savedSelection));
    }
  }, []);

  const toggleSelectAll = () => {
    // Get the current list from localStorage
    const storedSelection =
      JSON.parse(localStorage.getItem("selectedConversations")) || [];

    // If all items are currently selected, deselect them
    if (storedSelection.length === files?.length) {
      setUserSelectedDocs([]);
      localStorage.setItem("selectedConversations", JSON.stringify([]));
    } else {
      // Otherwise, select all items
      setUserSelectedDocs(files?.map((file) => file.name));
      localStorage.setItem(
        "selectedConversations",
        JSON.stringify(files?.map((file) => file.name))
      );
    }
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter data based on searchTerm
  const filteredData =
    files?.length >= 1 &&
    files?.filter((conversation) =>
      conversation.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= SIDEBAR_AUTO_CLOSE_MAX_SCREEN_WIDTH) {
        setIsCollapsed(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getImageByExtension = (filetype) => {
    switch (filetype.toLowerCase()) {
      case "pdf":
        return pdfIcon;

      case "txt":
        return txtIcon;

      case "ppt":
        return pptIcon;

      case "pptx":
        return pptIcon;

      case "epub":
        return epubIcon;

      case "rft":
        return rftIcon;

      case "doc":
        return msWordIcon;

      case "docx":
        return msWordIcon;

      default:
        return weburlIcon;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        window.innerWidth <= SIDEBAR_AUTO_CLOSE_MAX_SCREEN_WIDTH &&
        (!toggleButtonRef.current ||
          !toggleButtonRef.current.contains(event.target))
      )
        setIsCollapsed(true);
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isCollapsed]);

  return (
    <div style={{ position: "relative" }}>
      <aside
        ref={sidebarRef}
        className={`sidebar-wrapper text-white top-0 left-0 z-40 h-safe-screen relative sidebar bg-[#ebebeb] ${
          isCollapsed ? "w-0" : "w-80"
        } transition-all duration-300`}
        aria-label="Sidebar"
        style={{ borderRight: "1px solid #cdcdcd" }}
      >
        <div className="h-full overflow-y-auto bg-gray-50 dark:bg-gray-800 flex flex-col">
          <div className="h-14 mx-5 flex gap-2 items-center justify-start">
            {isCollapsed ? (
              <>
                <TooltipContainer
                  style={{ position: "absolute", top: "10px" }}
                  content={"Open sidebar"}
                  id={"open-sidebar-tooltip"}
                >
                  <RoundedButton
                    onClick={toggleSidebar}
                    ref={toggleButtonRef}
                    icon={
                      <Icon
                        icon={sidebar}
                        size={24}
                        className="text-[#5d5d5d]"
                      />
                    }
                  ></RoundedButton>
                </TooltipContainer>
              </>
            ) : (
              <TooltipContainer
                content={"Close sidebar"}
                id={"close-sidebar-tooltip"}
              >
                <RoundedButton
                  onClick={toggleSidebar}
                  icon={
                    <Icon icon={sidebar} size={24} className="text-[#5d5d5d]" />
                  }
                ></RoundedButton>
              </TooltipContainer>
            )}
            <a
              href="/"
              className="flex items-center space-x-3 rtl:space-x-reverse absolute top-1 left-20 w-max bg-[#d6d6d6] rounded-lg z-1"
            >
              <img className="navbvar-logo" src={logo1} alt=" Logo" />
            </a>
          </div>

          {isPopupOpen && (
            <FileUploadPopup onClose={togglePopup}>
              <FilesUploadedData
                popupMode={true}
                closePopup={togglePopup}
                onFileUpload={(filename, fileType) => {
                  // add newly uploaded file to the list
                  setFiles([
                    ...files,
                    {
                      name: filename,
                      type: fileType,
                    },
                  ]);
                }}
              />
            </FileUploadPopup>
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
              margin: "10px 15px",
            }}
          >
            <form
              className="flex-1"
              style={{ opacity: files?.length > 1 ? 1 : 0.3 }}
            >
              <div className="relative w-12/12">
                <div className="absolute inset-y-0 start-3 flex items-center pointer-events-none text-[#5d5d5d]">
                  <svg
                    className="h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  disabled={files?.length <= 1}
                  value={searchTerm}
                  onChange={handleSearchChange}
                  type="search"
                  id="default-search"
                  className="block w-full py-2 px-3 pl-8 text-md text-gray-900 border-2 rounded-xl text-[#5d5d5d]"
                  placeholder="Search File Name"
                  required
                />
              </div>
            </form>
            <TooltipContainer
              id="add-new-files-tooltip"
              content={"Add new files/web pages"}
            >
              <Icon
                icon={attachment}
                size={24}
                disabled={loading}
                onClick={togglePopup}
                className="text-[#5d5d5d]"
              />
            </TooltipContainer>
          </div>

          {files?.length >= 2 && (
            <div className="form-group flex items-center mx-6 mt-2 pb-1 border-b-2 border-[#ababab] justify-between">
              <input
                onChange={toggleSelectAll}
                checked={
                  userSelectedDocs.length === files?.length && files?.length > 0
                }
                type="checkbox"
                id="html"
              />
              <label htmlFor="html" className="font-semibold">
                Select All
              </label>
              <TooltipContainer
                content={"Delete all documents"}
                id={"delete-document-tooltip"}
              >
                <button
                  onClick={handleAllConversationsSelectionDelete}
                  className=" text-[#5d5d5d] font-semibold  "
                >
                  Delete All
                </button>
              </TooltipContainer>
            </div>
          )}

          <div
            style={{
              overflowY: "auto",
              scrollbarWidth: "thin",
              scrollbarColor: "#f6874c grey",
            }}
            className="flex-1"
          >
            {filteredData.length > 0 &&
              filteredData?.map((conversation) => {
                
                let nameChunks = conversation.name.split(".");
                const fileTpe = nameChunks[nameChunks.length - 1];
                const imageUrl = getImageByExtension(fileTpe);

                let fileNames = conversation && conversation.name;
                const isSelected = userSelectedDocs.includes(conversation.name);
                return (
                  <li
                    className={`list-none cursor-pointer`}
                    key={conversation.uid}
                  >
                    <div
                      className={`flex justify-between items-center my-3 mx-4 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group ${
                        activeDocument === conversation.id ? "bg-gray-300" : ""
                      }`}
                      style={{
                        padding: "3px 8px",
                        backgroundColor: isSelected ? "#cfcfcf" : "",
                      }}
                    >
                      <img src={imageUrl} style={{ width: "25px" }} />
                      <span
                        title={conversation.name}
                        style={{ wordBreak: "break-word" }}
                        onClick={() =>
                          !loading &&
                          handleConversationSelection(conversation.name)
                        }
                        className={`limit-text flex-1 ms-3 font-semibold text-[#5d5d5d]`}
                      >
                        {fileNames}
                      </span>
                      <TooltipContainer
                        content={"Delete this document"}
                        id={"delete-document-tooltip"}
                      >
                        <Icon
                          icon={ic_delete_outline}
                          size={24}
                          className="text-[#d11a2a]"
                          onClick={() =>
                            !loading &&
                            handleConversationSelectionDelete(conversation.name)
                          }
                        />
                      </TooltipContainer>
                    </div>
                  </li>
                );
              })}
          </div>
        </div>
      </aside>

      {/* Delete confirmation modal */}

      {showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40 ">
          <div
            style={{
              height: "200px",
              background: "#e6e6e6",
              position: "relative",
              width: "100%",
              maxWidth: "500px",
              marginTop: "-60px",
              padding: "20px",
              textAlign: "center",
            }}
          >
            <p className="text-black font-semibold">
              Do you want to delete this document ?
            </p>
            <div className="flex justify-between mt-20">
              <button
                className="p-2 w-20 bg-secondary text-white"
                onClick={handleDeleteConfirmation}
              >
                Yes
              </button>
              <button
                className="p-2  w-20 bg-secondary text-white"
                onClick={() => setShowDeleteModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Delete All  confirmation modal */}

      {showDeleteAllModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40 ">
          <div
            style={{
              height: "200px",
              background: "#e6e6e6",
              position: "relative",
              width: "100%",
              maxWidth: "500px",
              marginTop: "-60px",
              padding: "20px",
              textAlign: "center",
            }}
          >
            <p className="text-black font-semibold">
              Do you want to delete All documents ?
            </p>
            <div className="flex justify-between mt-20">
              <button
                className="p-2 w-20 bg-secondary text-white"
                onClick={handleDeleteAllConfirmation}
              >
                Yes
              </button>
              <button
                className="p-2  w-20 bg-secondary text-white"
                onClick={() => setShowDeleteAllModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SideBar;
