import React, { useState, useCallback, useEffect, useRef } from "react";
import { json, useNavigate } from "react-router-dom";
import { useAppData } from "../context/AppContext";
import tickicon from "../images/tickicon.png";
import { toast } from "react-hot-toast";
import exclamationicon from "../images/exclamationicon.png";
import FileUpload from "./FileUpload";
import { apiEndPoint, publicIP } from "./configuratiion";
import { isWebUri } from "valid-url";

import crossred from "../images/crossred.png";
import apiRequest from "../api/api";
const SupportedFiles = [
  "PDF",
  "TXT",
  "PPT",
  "PPTX",
  "EPUB",
  "RFT",
  "DOC",
  "DOCX",
  "XLSX",
];

// should have only two values because of toggle control
const DocTypes = [
  { type: "file", title: "Files" },
  { type: "url", title: "Web pages" },
];

let currUploadFileIndex = 0;

function FilesUploadedData(props) {
  const { popupMode, closePopup, onFileUpload } = props;
  const { authUser } = useAppData();
  const questionasnComp = "fileuploaded";

  const navigate = useNavigate();

  const [filesToUpload, setFilesToUpload] = useState([]);
  const [webUrl, setWebUrl] = useState("");
  const [urlsToUpload, setUrlsToUpload] = useState([]);
  const [noDocsLoaded, setnoDocsLoaded] = useState(false);
  const [rejectedFiles, setFilesRejection] = useState(null);
  const videoRef = useRef(null);
  const [docType, setDocType] = useState(DocTypes[0].type);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [evaluatedFilesToUpload, setEvaluatedFilesToUpload] = useState({
    files: [],
    urls: [],
  });
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [fileInProgressName, setFileInProgressName] = useState("");
  const [uploadedFilenames, setUploadedFilenames] = useState([]);
  const [failedFilenames, setFailedFilenames] = useState([]);

  // Callback function to handle data from child
  const handleFileData = useCallback((data) => {

    setFilesRejection((prevFiles) => {
      const validPrevFiles = Array.isArray(prevFiles) ? prevFiles : [];
      const newFiles = data.filter(
        (newFile) =>
          !validPrevFiles.some((file) => file.file.path === newFile.file.path)
      );

      if (newFiles.length === 0) {
        return prevFiles;
      }

      return [...validPrevFiles, ...newFiles];
    });
  }, []);

  //  function to remove a specific file
  const removeUnsupportedFiles = (pathToRemove) => {
    setFilesRejection((prevFiles) => {
      const validPrevFiles = Array.isArray(prevFiles) ? prevFiles : [];
      const updatedFiles = validPrevFiles.filter(
        (file) => file.file.path !== pathToRemove
      );
      return updatedFiles;
    });
  };

  const handleToggle = () => {
    if (docType == DocTypes[0].type) {
      setDocType(DocTypes[1].type);
    } else {
      setDocType(DocTypes[0].type);
    }
  };

  useEffect(() => {
    if (
      evaluatedFilesToUpload.files.length > 0 ||
      evaluatedFilesToUpload.urls.length > 0
    ) {
      // files/urls have been evaluated
      // start uploading files/urls
      setUploadComplete(false);
      setUploadInProgress(true);
      currUploadFileIndex = 0;
      uploadNextFile();
    }
  }, [evaluatedFilesToUpload]);

  useEffect(() => {
    if (fileInProgressName == "" && uploadInProgress) {
      uploadNextFile();
    }
  }, [fileInProgressName]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.9;
    }
  }, []);

  const resetUploadRelatedVars = () => {
    setEvaluatedFilesToUpload({ urls: [], files: [] });
    setUploadComplete(false);
    setUploadedFilenames([]);
    setFailedFilenames([]);
    setFileInProgressName("");
  };

  const handleFilesSelected = (files) => {
    setFilesToUpload((prevFiles) => [
      ...prevFiles,
      ...files.map((file) => ({ ...file, file: file })),
    ]);
    resetUploadRelatedVars();
  };

  const removeFile = (path) => {
    setFilesToUpload((files) => files.filter((file) => file.path !== path));
  };

  const removeUrl = (urlName) => {
    setUrlsToUpload((urls) => urls.filter((url) => url !== urlName));
  };

  const removeAll = () => {
    setFilesToUpload([]);
  };

  function isValidURL(url) {
    return isWebUri(url);
  }

  const addUrls = () => {
    resetUploadRelatedVars();
    if (!webUrl) {
      toast.error("Please add URL!");
      return;
    }

    if (urlsToUpload.findIndex((u) => u == webUrl) != -1) {
      toast.error("URL already added!");
      return;
    }

    if (!isValidURL(webUrl)) {
      toast.error("Invalid URL");
      return;
    }

    setUrlsToUpload((preValue) => [...preValue, webUrl]); // add url
    setWebUrl(""); // reset
  };

  const removeAllUrls = () => {
    setUrlsToUpload([]);
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    setWebUrl(inputValue);
  };

  const evaluateFiles = async () => {
    let evaluatedFiles = await apiRequest(
      "POST",
      "evaluateFiles",
      JSON.stringify({
        id: authUser?.user_id,
        docType: docType,
        files:
          docType == "file" ? filesToUpload.map((file) => file.file.name) : [],
        urls: docType == "url" ? urlsToUpload.map((url) => url) : [],
      })
    );
    return evaluatedFiles;
  };

  // uploads next file or url
  const uploadNextFile = async () => {
    // initialize with empty array
    let items = []; // contains urls or files
    if (docType == "file") {
      items = evaluatedFilesToUpload.files.filter((f) => !f.alreadyExists);
    } else if (docType == "url") {
      items = evaluatedFilesToUpload.urls.filter((f) => !f.alreadyExists);
    }

    if (currUploadFileIndex >= items.length) {
      setUploadInProgress(false);
      setUploadComplete(true);
      if (docType == "file") {
        setFilesToUpload([]); //reset
      } else if (docType == "url") {
        setUrlsToUpload([]); //reset
      }
      return;
    }

    const item = items[currUploadFileIndex];
    let data = null;
    if (docType == "file") {
      data = filesToUpload.find((f) => f.file.name === item.filename);
    } else if (docType == "url") {
      data = urlsToUpload.find((u) => u === item.filename);
    }

    if (data) {
      setFileInProgressName(item.filename);

      try {
        const result = await uploadFile(data);

        if (result.error) {
          // window.alert(result.error);
          setFailedFilenames((prev) => [...prev, item.filename]);
        } else {
          console.log(`File uploaded successfully.`);
          setUploadedFilenames((prev) => [...prev, item.filename]);
          onFileUpload && onFileUpload(item.filename, docType);
        }
      } catch (error) {
        console.error("Error uploading");
        setFailedFilenames((prev) => [...prev, item.filename]);
      }
    } else {
      setFailedFilenames((prev) => [...prev, item.filename]);
    }
    currUploadFileIndex++;
    setFileInProgressName("");
  };

  // upload file or url
  const uploadFile = async (data) => {
    const formData = new FormData();
    formData.append("id", authUser?.user_id);
    formData.append("docType", docType);
    if (docType == "file") {
      formData.append("files", data.file);
    } else {
      formData.append("urls", data);
    }
    try {
      const responseData = await apiRequest(
        "POST",
        "/uploadFile",
        formData,
        null,
        true
      );
      return responseData;
    } catch (err) {
      const uploadedFilesErr=err.response.data?.message
      toast.err(uploadedFilesErr)
      console.error("Error uploading file:", err.message);
    }
  };

  // start uploading urls
  const uploadUrls = async () => {
    let evaluatedFiles = await evaluateFiles();
    setEvaluatedFilesToUpload(evaluatedFiles);
  };

  const uploadFiles = async () => {
    let evaluatedFiles = await evaluateFiles();
    setEvaluatedFilesToUpload(evaluatedFiles);
  };

  useEffect(() => {
    // Revoke the data URIs to avoid memory leaks
    return () =>
      filesToUpload.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [filesToUpload]);

  const handleNavigateConversations = () => {
    if (popupMode) {
      // refresh page so that the latest list of files is loaded
      closePopup && closePopup();
    } else {
      navigate(`/conversation`);
    }
  };

  return (
    <div>
      <div className="relative  text-white  fileUpload-headding-wrapper">
        <div className="flex justify-center flex-col items-center gap-10">
          {docType == "url" && (
            <h1 className=" text-5xl font-bold text-center text-orangeyellow fileUpload-headding">
              Transforming{" "}
              <span style={{ color: "white" }} className="">
                Websites
              </span>{" "}
              into Conversations
            </h1>
          )}
          {docType == "file" && (
            <h1 className=" text-5xl font-bold text-center text-orangeyellow fileUpload-headding">
              It's not just <span style={{ color: "white" }}>Reading</span>{" "}
              anymore, it's a Conversation
            </h1>
          )}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "600px",
          margin: "0px auto",
          textAlign: "center",
          color: "white",
          maxHeight: "70vh",
        }}
      >
        <div className=" flex justify-center text-white mt-2 sm:mt-5">
          <div className="flex justify-between ">
            <input
              disabled={uploadInProgress}
              type="checkbox"
              id="toggle"
              className="toggleCheckbox"
              checked={docType == "url"}
              onChange={handleToggle}
            />
            <label
              htmlFor="toggle"
              className="toggleContainer"
              style={{ opacity: uploadInProgress ? 0.5 : 1 }}
            >
              {DocTypes.map((dType,index) => {
                return (
                  <div key={index} className={dType.url == docType ? "selected" : ""}>
                    {dType.title}
                  </div>
                );
              })}
            </label>
          </div>
        </div>


        {docType == "url" && (
          <div className="w-full m-auto">
            <h1 className=" text-lg mt-4 mb-4">Enter Your URL</h1>

            <div className=" mt-2 flex justify-between gap-2">
              <input
                disabled={uploadInProgress}
                multiple
                required
                placeholder="https://example.com"
                onChange={handleChange}
                value={webUrl}
                type="url"
                style={{
                  padding: "6px 20px",
                  border: "1px solid #F6874C",
                  width: "100%",
                  background: "transparent",
                }}
              />

              <button
                disabled={uploadInProgress}
                className=" bg-[#414141] px-4 py-2 border-2 border-white  hover:bg-white hover:text-[#414141] font-semibold "
                onClick={addUrls}
                style={{ opacity: uploadInProgress ? 0.5 : 1 }}
              >
                Add
              </button>
            </div>

            {urlsToUpload.length > 0 &&
            evaluatedFilesToUpload.urls.length == 0 ? (
              <div
                style={{
                  flex: "1 1",
                  maxHeight: "300px",
                  overflowY: "auto",
                  scrollbarWidth: "thin",
                  scrollbarColor: "#f6874c grey",
                }}
                className="mt-5"
              >
                {urlsToUpload?.map((url,index) => {
                  return (
                    <>
                      <li
                      key={index}
                        className="flex justify-between px-2 py-1 uploaded-docs"
                        style={{ border: "1px solid grey", display: "flex" }}
                      >
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          title={url}
                        >
                          {url}
                        </span>
                        <button
                          className=" text-orangeyellow"
                          type="button"
                          onClick={() => removeUrl(url)}
                        >
                          <img
                            width={25}
                            style={{ maxWidth: "initial" }}
                            src={crossred}
                          />
                        </button>
                      </li>
                    </>
                  );
                })}
              </div>
            ) : null}

            {noDocsLoaded ? (
              <p style={{ color: "#ff5441", marginTop: "10px" }}>
                No Documents Loaded
              </p>
            ) : (
              ""
            )}

            {urlsToUpload.length > 0 &&
            evaluatedFilesToUpload.urls.length == 0 ? (
              <div className="flex justify-between items-center mt-4">
                <button
                  disabled={uploadInProgress}
                  className=" bg-[#414141] px-4 py-2 border-2 border-white hover:bg-white hover:text-[#414141] font-semibold "
                  style={{ opacity: uploadInProgress ? 0.5 : 1 }}
                  type="button"
                  onClick={removeAllUrls}
                >
                  Remove all urls
                </button>

                <button
                  disabled={uploadInProgress}
                  className=" bg-[#414141] px-4 py-2 border-2 border-white  hover:bg-white hover:text-[#414141] font-semibold "
                  onClick={uploadUrls}
                  style={{ opacity: uploadInProgress ? 0.5 : 1 }}
                >
                  Process URLs
                </button>
              </div>
            ) : (
              ""
            )}

            {/* evaluated urls list  */}

            {evaluatedFilesToUpload?.urls?.length > 0 && (
              <div
                className="mt-5"
                style={{
                  flex: "1 1",
                  maxHeight: "300px",
                  overflowY: "auto",
                  scrollbarWidth: "thin",
                  scrollbarColor: "#f6874c grey",
                }}
              >
                {" "}
                {evaluatedFilesToUpload.urls?.map((url) => {

                  return (
                    <li
                      className="flex  justify-between px-2 py-1 uploaded-docs"
                      style={{ border: "1px solid grey" }}
                      key={url.filename}
                    >
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        title={url.filename}
                      >
                        {url.filename}
                      </span>
                      {uploadInProgress &&
                      fileInProgressName == url.filename ? (
                        <div style={{ paddingRight: "8px" }}>
                          <div
                            style={{ position: "relative" }}
                            className="inner"
                          ></div>
                        </div>
                      ) : null}
                      {uploadedFilenames.includes(url.filename) ? (
                        <img
                          title="Uploaded successfully"
                          width={25}
                          src={tickicon}
                        />
                      ) : null}
                      {failedFilenames.includes(url.filename) ? (
                        <img
                          title="Something went wrong!"
                          width={25}
                          src={exclamationicon}
                        />
                      ) : null}

                      {url.alreadyExists && (
                        <span style={{ whiteSpace: "nowrap", color: "orange" }}>
                          Already Exists
                        </span>
                      )}
                    </li>
                  );
                })}
              </div>
            )}
          </div>
        )}

        {docType == "file" && (
          <div className="w-full m-auto">
            <FileUpload
              onFilesUploaded={handleFilesSelected}
              uploadedFiles={filesToUpload}
              displayStyles={questionasnComp}
              handleFileData={handleFileData}
              uploadInProgress={uploadInProgress}
              SupportedFiles={SupportedFiles}
            />

            {rejectedFiles?.length > 0 ? (
              <>
                <span className="mt-2 font-bold text-orangeyellow">
                  Unsupported Files
                </span>
                <ul
                  style={{
                    maxHeight: "100px",
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#f6874c grey",
                  }}
                >
                  {" "}
                  {rejectedFiles?.map((file) => {
                    let path = file.file.path;
                    return (
                      <>
                        <li
                          className="flex justify-between px-2 py-1 uploaded-docs"
                          style={{ border: "1px solid #ff6767" }}
                          key={path}
                        >
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {path}
                          </span>

                          <div className="flex items-center gap-3">
                            <button
                              className=" text-orangeyellow"
                              type="button"
                              onClick={() => removeUnsupportedFiles(path)}
                            >
                              <img width={25} src={crossred} />
                            </button>
                          </div>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </>
            ) : (
              ""
            )}

            {filesToUpload?.length > 0 &&
            evaluatedFilesToUpload.files.length == 0 ? (
              <>
                <span className="font-bold text-orangeyellow mt-2">
                  Supported Files
                </span>
                <div
                  style={{
                    flex: "1 1",
                    maxHeight: "200px",
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#f6874c grey",
                  }}
                >
                  {filesToUpload.map((file) => {
                    let path = file.path;

                    return (
                      <>
                        <li
                          className="flex justify-between px-2 py-1 uploaded-docs"
                          style={{ border: "1px solid grey" }}
                          key={path}
                        >
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {path}
                          </span>
                          <button
                            className=" text-orangeyellow"
                            type="button"
                            onClick={() => removeFile(path)}
                          >
                            <img width={25} src={crossred} />
                          </button>
                        </li>
                      </>
                    );
                  })}
                </div>
              </>
            ) : (
              ""
            )}

            {evaluatedFilesToUpload?.files?.length > 0 && (
              <>
                <span className="font-bold text-orangeyellow mt-5">
                  Supported Files
                </span>
                <div
                  style={{
                    flex: "1 1",
                    maxHeight: "200px",
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#f6874c grey",
                  }}
                >
                  {" "}
                  {evaluatedFilesToUpload.files.map((file) => {
                    const path = file.filename; 

                    return (
                      <li
                        className="flex justify-between px-2 py-1 uploaded-docs"
                        style={{ border: "1px solid grey" }}
                        key={path}
                      >
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {path}
                        </span>
                        {uploadInProgress && fileInProgressName == path ? (
                          <div style={{ paddingRight: "8px" }}>
                            <div
                              style={{ position: "relative" }}
                              className="inner"
                            ></div>
                          </div>
                        ) : null}
                        {uploadedFilenames.includes(file.filename) ? (
                          <img
                            title="Uploaded successfully"
                            width={25}
                            src={tickicon}
                          />
                        ) : null}
                        {failedFilenames.includes(file.filename) ? (
                          <img
                            title="Upload failed!"
                            width={25}
                            src={exclamationicon}
                          />
                        ) : null}

                        {file.alreadyExists && (
                          <span
                            style={{ whiteSpace: "nowrap", color: "orange" }}
                          >
                            Already Exists
                          </span>
                        )}
                      </li>
                    );
                  })}
                </div>
              </>
            )}

            {filesToUpload?.length > 0 &&
            evaluatedFilesToUpload.files.length == 0 ? (
              <div className="flex justify-between mt-3 uploads-btns">
                <button
                  disabled={uploadInProgress}
                  className=" bg-[#414141] px-1 py-2 border-2 border-white hover:bg-white hover:text-[#414141] font-semibold "
                  style={{ opacity: uploadInProgress ? 0.5 : 1 }}
                  type="button"
                  onClick={removeAll}
                >
                  Remove all files
                </button>

                <button
                  disabled={uploadInProgress}
                  className=" bg-[#414141] px-1 py-2 border-2 border-white  hover:bg-white hover:text-[#414141] font-semibold "
                  style={{ opacity: uploadInProgress ? 0.5 : 1 }}
                  onClick={uploadFiles}
                >
                  Upload Documents
                </button>
              </div>
            ) : null}
          </div>
        )}

        {uploadInProgress && (
          <div>
            <div className="loader">
              <div className="outer"></div>
              <div className="middle"></div>
              <div className="inner"></div>
            </div>
            <p className="text-3xl">Loading...</p>
          </div>
        )}

        {uploadComplete ? (
          <div className="text-center sm:text-right mt-3 uploads-btns">
            <button
              className=" bg-[#414141] px-1 py-2 border-2 border-white  hover:bg-white hover:text-[#414141] font-semibold "
              onClick={handleNavigateConversations}
            >
              Proceed to Conversation
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default FilesUploadedData;
