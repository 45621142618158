import React from 'react'
import Navbar from './Navbar'

function PrivacyPolicy() {
  return (
<div className="bg-gray-100">
    <Navbar/>
    <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-4 text-orangeyellow">Privacy Policy</h1>

        <p className="mb-4">
        Welcome to Chat with Docs. We value your privacy and are committed to protecting your personal data. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you use our website and services.
        </p>

        <h2 className="text-2xl font-bold mb-2">Information We Collect</h2>

        <p className="mb-4">
            We may collect the following information:
        </p>

        <ul className="list-disc list-inside mb-4">
            <li><span className=' font-semibold'>Personal Information:</span> When you sign up for our service, we collect personal information such as your name, email address, and any other information you provide during the registration process.</li>
            <li><span className=' font-semibold'>Document Uploads:</span> Any documents you upload to the platform are stored securely. These documents may contain personal or sensitive information, and we take steps to protect this data.</li>
            <li><span className=' font-semibold'>Usage Data:</span> We collect information about your interactions with our service, including the documents you upload, the conversations you have with the documents, and any changes or deletions you make.</li>

        </ul>

        <h2 className="text-2xl font-bold mb-2">How We Use the Information</h2>

        

        <ul className="list-disc list-inside mb-4">
            <li> <span className=' font-semibold'>To Provide Services:</span> We use your personal information to create and manage your account, provide access to our platform, and enable you to upload and interact with your documents.</li>
            <li>  <span className=' font-semibold'>To Improve Our Services:</span> We use usage and technical data to understand how our services are used and to improve and personalize your experience.</li>
            <li>  <span className=' font-semibold'>To Communicate with You: </span>We may use your email address to send you updates, notifications, and other communications related to your account and our services.</li>
        </ul>

        <h2 className="text-2xl font-bold mb-2">Disclosure of Your Information</h2>

        <ul className="list-disc list-inside mb-4">
            <li> <span className=' font-semibold'>Service Providers:</span> We may share your information with third-party service providers who assist us in operating our platform and providing our services, such as cloud storage providers and data analytics services..</li>
            <li> <span className=' font-semibold'>Legal Requirements:</span> We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or a government agency).</li>
            <li> <span className=' font-semibold'>Business Transfers: </span>In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal information may be transferred to the acquiring entity.</li>
        </ul>

        <h2 className="text-2xl font-bold mb-2">Data Security</h2>

        <p className="mb-4">
        We implement appropriate technical and organizational measures to protect your personal information against accidental or unlawful destruction, loss, alteration, unauthorized disclosure, or access.
        </p>

        <h2 className="text-2xl font-bold mb-2">Data Retention</h2>

        <p className="mb-4">
        We retain your personal information only for as long as necessary to fulfill the purposes for which we collected it, including for the purposes of satisfying any legal, accounting, or reporting requirements.
        </p>

        <h2 className="text-2xl font-bold mb-2">Your Rights</h2>

        <p className="mb-4">
        You have the right to access, correct, or delete your personal information. You may also have the right to object to or restrict certain types of data processing. To exercise these rights, please contact us using the information provided below.
        </p>

        <h2 className="text-2xl font-bold mb-2">Changes to This Privacy Policy</h2>

        <p className="mb-4">
        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review this Privacy Policy periodically for any changes.
        </p>

        <h2 className="text-2xl font-bold mb-2">Contact Us</h2>


        <p className="mb-4">
        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
        </p>
        <p > <span className=' font-semibold'>Email: </span>help@chatdoc.com</p>

    </div>
</div>  )
}

export default PrivacyPolicy