import React, { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { useAppData } from '../context/AppContext'

function useLogout() {
    const navigate=useNavigate()
    const { setAuthUser } = useAppData()
    const logout = async () => {
        try {
            localStorage.clear()
            setAuthUser(null)
            navigate("/login")
        }

        catch (err) {
            toast.error(err.message)
        }

        finally {
        }
    }

    return {logout};

}

export default useLogout