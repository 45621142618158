import React, { useState } from "react";
import FilesUploadedData from "./FilesUploadedData";

const FileUploadPopup = ({ onClose, children }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-container flex flex-col">
        <div className="w-full h-10 bg-[#353535] relative">
          <button className="close-button" onClick={onClose}>
            ×
          </button>
        </div>
        <div className="popup-content bg-[#414141]">{children}</div>
      </div>
    </div>
  );
};

export default FileUploadPopup;
