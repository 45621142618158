import React, { useState } from "react";

const Rating = ({ rating, setRating }) => {
  const [hover, setHover] = useState(0);

  // Function to handle setting the rating
  const handleRatingClick = (ratingValue) => {
    setRating(ratingValue);
  };

  return (
    <div className="rating">
      {[...Array(5)].map((star, index) => {
        const ratingValue = index + 1;
        return (
          <label key={index}>
            <input
              type="radio"
              name="rating"
              value={ratingValue}
              onClick={() => handleRatingClick(ratingValue)}
              style={{ display: "none" }}
            />
            <span
              className="star"
              onMouseEnter={() => setHover(ratingValue)}
              onMouseLeave={() => setHover(0)}
              style={{
                cursor: "pointer",
                color: ratingValue <= (hover || rating) ? "#ffc107" : "#e4e5e9",
                fontSize: "1.5rem",
              }}
            >
              &#9733;
            </span>
          </label>
        );
      })}
    </div>
  );
};

export default Rating;
