import logo from "./logo.svg";
import "./App.css";
import Signup from "./components/Signup";
import SignIn from "./components/SignIn";
import {
  Route,
  Routes,
  BrowserRouter,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useAppData } from "./context/AppContext";
import ForgetPassoword from "./components/ForgetPassoword";
import ResetPassword from "./components/ResetPassword";
import Home from "./pages/Home";
import Conversation from "./components/Conversation";
import { useEffect } from "react";
import About from "./components/About";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ProtectedRoute from "./components/ProtectedRoutes";
import AdminPage from "./pages/admin/AdminPage";
import PricingPlans from "./components/PricingPlans";
function App() {
  const navigate = useNavigate();
  const { authUser } = useAppData();
  const token = authUser?.access_token;
  const isAdmin = authUser?.isAdmin;

  useEffect(() => {
    if (!authUser) {
      navigate("/login");
    }
  }, [authUser]);

  return (
    <div>
      <Routes>
        {/* Public Routes */}
        <Route
          path="/login"
          element={authUser ? <Navigate to="/" /> : <SignIn />}
        />
        <Route path="/admin" element={<AdminPage />} />
        <Route
          path="/signup"
          element={authUser ? <Navigate to="/" /> : <Signup />}
        />{" "}
        <Route path="/forgot-password" element={<ForgetPassoword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        {/* <Route path="/pricing" element={<PricingPlans />} /> */}
        {/* Protected Routes */}
        <Route path="/" element={<ProtectedRoute element={Home} />} />
        {/* <Route
            path="/conversation/:id"
            element={<ProtectedRoute element={Conversation} />}
          /> */}
        <Route
          path="/conversation"
          element={<ProtectedRoute element={Conversation} />}
        />
        {/* Catch-all Route */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>

      <Toaster />
    </div>
  );
}

export default App;
