import AdminSidebar from "./adminComponents/AdminSidebar";

const AdminLayout = ({ children }) => {
  return (
    <div className="min-h-screen flex flex-col">
      {/* <AdminHeader /> */}

      <div className="flex flex-1">
        <AdminSidebar />
        {/* Main content */}
        <main className="flex-1 p-6 bg-gray-100">{children}</main>
      </div>
    </div>
  );
};

export default AdminLayout;
