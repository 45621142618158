import React, { useState, useEffect, useRef } from "react";
import MarkdownRenderer from "./MarkdownRenderer";

const TypingAnimation = ({
  text,
  setTyping,
  typingText,
  typing,
  setTypingText,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const isAnimatingRef = useRef(true); // Ref to control animation state
  const paragraphsRef = useRef([]); // Ref for paragraph elements
  const [copyText, setCopyText] = useState([]);

  useEffect(() => {
    const interval = setTimeout(() => {
      if (isAnimatingRef.current) {
        // Check if animation is paused
        let currentText = text.substring(0, currentIndex);
        setTypingText(currentText);
        setCurrentIndex((prevIndex) => {
          if (prevIndex < text.length) {
            return prevIndex + 10;
          } else {
            clearTimeout(interval);
            isAnimatingRef.current = false; // Set animation to false when complete
            setTyping(false);
            return prevIndex;
          }
        });
      }
    }, 20);

    return () => clearTimeout(interval);
  }, [text, currentIndex]);

  useEffect(() => {
    scrollDown();
  }, [typingText]);

  const scrollDown = () => {
    // Scroll to bottom when displayText changes
    let refs = paragraphsRef.current.filter((c) => c);
    if (refs.length > 0) {
      const lastParagraph = refs[refs.length - 1];
      if (lastParagraph) {
        lastParagraph.scrollIntoView();
      }
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setCopyText([false]);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, [copyText]);

  return (
    <>
      <div
        className="mt-2 mb-2 animated-text rounded-lg p-2 pl-4"
        style={{
          overflowY: "auto",
          // whiteSpace: "pre-wrap",
          backgroundColor: "#ebebeb",
        }}
      >
        {typingText.split("\n").map((line, index) => (
          <div
            className="my-2"
            ref={(el) => (paragraphsRef.current[index] = el)}
            key={index}
          >
            <MarkdownRenderer markdownContent={line} />
          </div>
        ))}
      </div>
    </>
  );
};

export default TypingAnimation;
