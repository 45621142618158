import axios from 'axios';
import React, { useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom';
import useSignUp from '../hooks/useSignUp';
import logo1 from "../images/logo1.png"
import logo2 from "../images/logo3.png"

function Signup() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("")
    const [name, setName] = useState("")
    const navigat = useNavigate()

    const { signup, loading } = useSignUp()
    const handleClickSignUp = async (e) => {
        e.preventDefault();

        await signup(name, email, password, confirmPassword)

    }
    return (
        <div class="flex min-h-full h-safe-screen flex-col justify-center px-6 py-28 lg:px-8 SignupWrapper">


<div class="bg"></div>
<div class="bg bg2"></div>
<div class="bg bg3"></div>


            <div class="sm:mx-auto sm:w-full sm:max-w-sm ">
                <img class="mx-auto  w-16" src={logo2} alt="Your Company" style={{ width: "260px" }}  />
                <h2 class="mt-5 text-center text-2xl font-bold  tracking-tight  text-orangeyellow ">Sign Up</h2>
            </div>

            <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm z-10 relative">
                <form class="space-y-6" action="#" method="POST">

                    <div>
                        <label for="email" class="block text-sm font-medium leading-6">Name</label>
                        <div class="mt-2">
                            <input value={name} onChange={(e) => { setName(e.target.value) }} id="name" name="name" type="text" required class=" pl-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>

                    <div>
                        <label for="email" class="block text-sm font-medium leading-6 ">Email address</label>
                        <div class="mt-2">
                            <input value={email} onChange={(e) => { setEmail(e.target.value) }} id="email" name="email" type="email" autocomplete="email" required class="  pl-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>

                    <div>
                        <div class="flex items-center justify-between">
                            <label for="password" class="block text-sm font-medium leading-6">Password</label>

                        </div>


                        <div class="mt-2">
                            <input value={password} onChange={(e) => { setPassword(e.target.value) }} id="password" name="password" type="password" autocomplete="current-password" required class=" pl-1  block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>

                    <div>
                    <div class="flex items-center justify-between">
                            <label for="password" class="block text-sm font-medium leading-6">Confirm Password</label>

                        </div>
        
                        <div class="mt-2">
                            <input value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} id="password" name="password" type="password" autocomplete="current-password" required class=" pl-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                    </div>

                    <div>
                        <button onClick={handleClickSignUp} type="submit" class="flex w-full justify-center rounded-md  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 bg-secondary">Sign Up</button>
                    </div>
                </form>

                <p class="mt-10 text-center text-sm text-gray-500">
                    Already have an account?
                    <Link to="/login" class="font-semibold leading-6 text-secondary"> Login here</Link>
                </p>
            </div>
        </div>
    )
}

export default Signup