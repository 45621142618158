import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { useAppData } from '../context/AppContext';

const ProtectedRoute = ({ element: Component, ...rest }) => {
    const { authUser } = useAppData();
    const token = authUser?.access_token;
    const isAdmin = authUser?.isAdmin; // Assuming `isAdmin` is a boolean in your authUser

    // Check if token exists and user is allowed to access the route
    if (token) {
        // Check if user is admin and route requires admin privileges
        if (rest.requiresAdmin && !isAdmin) {
            return <Navigate to='/main-page' />; // Redirect to main page or another appropriate page
        }
        return <Component {...rest} />;
    } else {
        return <Navigate to='/login' />;
    }
};

export default ProtectedRoute;
