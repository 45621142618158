import React from "react";

function RoundedContainer({ children, onClick, style }) {
  return (
    <div className={`dc-title`} style={style ?? {}} onClick={onClick}>
      {children}
    </div>
  );
}

export default RoundedContainer;
