import { createContext, useContext, useState } from "react";

export const AppDataContext  = createContext();

export const useAppData  = () => {
    return useContext(AppDataContext)
}

export const AppDataContextProvider  = ({ children }) => {
    const [authUser, setAuthUser] = useState(JSON.parse(localStorage.getItem("auth-user")) || null)
    // const [userData, setUserData] = useState(JSON.parse(localStorage.getItem("user-conversation")) || [])
    const [userSelectedDocs,setUserSelectedDocs]=useState([])
    const value = { authUser, setAuthUser,userSelectedDocs,setUserSelectedDocs };
    
    return <AppDataContext.Provider value={value}>{children}</AppDataContext.Provider>
}