// export const  apiEndPoint="http://172.19.91.57:3000"

// export const publicIP="http://203.130.21.18:3000"







const devIP = "http://172.19.91.57:3000"; // Development IP
const prodIP = "203.130.21.18"; // Production (public) IP
const domain="https://chatdocs.online"
// Function to get the server endpoint including the protocol
// export function getServerEndpoint() {
//   return window.location.protocol + '//' + getServerEndpointNoProtocol();
// }

// Function to get the server endpoint without the protocol
// export function getServerEndpointNoProtocol() {
//   let serverEndpoint = prodIP; // Default to development IP
//   if (process.env.NODE_ENV === "production") {
//     serverEndpoint = prodIP; // Use production IP if in production environment
//   }
//   return serverEndpoint;
// }
// Example usage of the API endpoint
// export const apiEndPoint = getServerEndpoint();
// export const apiEndPoint = getServerEndpoint();
export const apiEndPoint = domain;





