import React from 'react'
import AdminLayout from '../../components/AdminLayout'
import LLMSTable from '../../components/adminComponents/LLMSTable'

function AdminPage() {
  return (

  <AdminLayout>
      <div>
        <h1 className="text-2xl font-bold mb-4">Settings</h1>
        <hr style={{width:"60%",margin:"30px 0"}}/>
        <LLMSTable />
      </div>
    </AdminLayout>
  )
}

export default AdminPage