import React, { useState } from 'react'
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useAppData  } from '../context/AppContext';
import { apiEndPoint, publicIP } from '../components/configuratiion';
import apiRequest from '../api/api';

function useSignUp() {
    const navigat = useNavigate()
const {setAuthUser}=useAppData()
    const [loading, setLoading] = useState(false)
    const signup = async (name, email, password, confirmPassword) => {
        const success = handleInputErrors({ name, email, password, confirmPassword })

        if (!success) return;
        setLoading(true)
        try {
        const res=await apiRequest("POST",`/register`,JSON.stringify({name, email, password,confirmPassword}))
        console.log(res,"ressignup")
        if (res.message==="email already exists"){
            toast.error("email already exists")
            return
        }
    
        navigat("/login")

        }

        catch (err) {
            const SignupErr=err.response.data?.message

          toast.error(SignupErr)
        }

        finally{
            setLoading(false)

        }

    }

    return {loading,signup};
}

export default useSignUp


function handleInputErrors({ name, email, password, confirmPassword }) {
    if (!name || !email || !password || !confirmPassword) {
        toast.error("please fill in all fields")
        return false
    }

    if (password !== confirmPassword) {
        toast.error("passwords do not match")
        return false;
    }


    if (password.length < 6) {
        toast.error("passwords must be at least 6 characters")
        return false;
    }

    return true
}